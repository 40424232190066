<template>
  <v-row class="popup-background">
    <v-col cols="3">
      <v-card>
        <v-card-text class="py-5 px-2">
          <div class="text-h4 primary--text text-center mb-10">
            ข้อมูลลูกค้า
          </div>
          <v-text-field
            label="ชื่อลูกค้า"
            outlined
            dense
            disabled
            v-model="data.customer_name"
          />
          <v-text-field
            label="เลขทะเบียน"
            outlined
            dense
            disabled
            :value="`${data.car_plate} ${data.car_province}`"
          />
          <v-text-field
            label="ประเภทรถ"
            outlined
            dense
            disabled
            :value="carTypeName"
          />
          <v-data-table
            :headers="headers"
            :items="data.product"
            item-key="name"
            hide-default-footer
            disable-sort
          >
              <template v-slot:item="{ item }">
                <tr v-if="item.name !== ''">
                  <td>{{ item.name }}</td>
                  <td>{{ item.price | numeral('0,0.00') }}</td>
                </tr>
              </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="9">
      <v-card>
        <v-card-text class="py-5 px-10">
          <v-row>
            <v-col cols="7">
              <div class="text-h4 primary--text text-right mb-10">
                การชำระเงิน
              </div>
            </v-col>
            <!-- <v-col cols="5">
            <v-card>
                <span class="d-flex float-right">
                <DatePicker
                  label="วันที่การชำระเงิน"
                  :value="form.pick_up_date"
                  @onChange="(val) => (form.pick_up_date = val)"
                  :customAllowedDates="[0,6]"
                  :disabled="isUpdate"
                />
                <TimePicker
                  label="เวลารับ"
                  :value="form.pick_up_time"
                  @onChange="(val) => (form.pick_up_time = val)"
                  :disabled="isUpdate"
                />
                </span>
              </v-card>
          </v-col> -->
          </v-row>

          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <div class="fz-3">ยอดชำระ</div>
                  <div class="text-h4 primary--text text-right">
                    {{ data.net_price | numeral('0,0.00') }}
                  </div>
                </v-card-text>
              </v-card>

              <v-card class="mt-5">
                <v-card-text class="py-2">
                  <v-btn
                    :color="form.payment_type == 1 ? 'primary' : 'grey'"
                    text
                    x-large
                    block
                    @click="form.payment_type = 1"
                    >เงินสด</v-btn
                  >
                  <v-divider class="my-1" />
                  <v-btn
                    :color="form.payment_type == 2 ? 'primary' : 'grey'"
                    text
                    x-large
                    block
                    @click="form.payment_type = 2"
                    >โอน</v-btn
                  >
                  <v-divider class="my-1" />
                  <v-btn
                    :color="form.payment_type == 3 ? 'primary' : 'grey'"
                    text
                    x-large
                    block
                    @click="form.payment_type = 3"
                    >บัตรเครดิต</v-btn
                  >
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="5">
              <v-card>
                <v-card-text class="pb-10">
                  <div class="fz-3">ได้รับเงิน</div>
                  <v-row class="pt-4">
                    <v-col cols="3">
                      <v-btn
                        class="fz-3"
                        small
                        block
                        outlined
                        @click="addMoney(100)"
                        :disabled="loading"
                        >100</v-btn
                      >
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="fz-3"
                        small
                        block
                        outlined
                        @click="addMoney(500)"
                        :disabled="loading"
                        >500</v-btn
                      >
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="fz-3"
                        small
                        block
                        outlined
                        @click="addMoney(700)"
                        :disabled="loading"
                        >700</v-btn
                      >
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="fz-3"
                        small
                        block
                        outlined
                        @click="addMoney(1000)"
                        :disabled="loading"
                        >1,000</v-btn
                      >
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <v-btn
                        color="error"
                        class="fz-3 mb-2"
                        block
                        outlined
                        @click="addMoney()"
                        :disabled="loading"
                        >เต็มจำนวน</v-btn
                      >
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        name="getMoney"
                        class="mb-2"
                        outlined
                        dense
                        hide-details=""
                        autocomplete="off"
                        v-model.number="price.getMoney"
                        :error-messages="
                          $v.price.getMoney.$error ? $error_text : ''
                        "
                      />
                      <div class="error--text ml-15 mt-2">
                        {{ errors.getMoney }}
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <v-checkbox class="text-right" label=" ทั้งหมด " v-model="r"/> -->

                  <div class="fz-3 mt-5 red--text">เงินทอน</div>
                  <div class="text-h5 red--text text-right">
                    {{ change | numeral('0,0.00') }}
                  </div>

                  <div class="fz-3 mt-5">ค้างชำระ</div>
                  <div class="text-h5 text-right">
                    {{ overdue | numeral('0,0.00') }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col>
              <v-card>
                <v-card-text>
                  <template v-if="form.payment_type == 2">
                    <div class="fz-3">ธนาคาร</div>
                    <v-select
                      class="my-5 mb-2"
                      :items="banks"
                      v-model="form.pay_reference"
                      label=""
                      dense
                      hide-details
                      outlined
                    ></v-select>
                  </template>
                  <template v-if="form.payment_type == 3">
                    <div class="fz-3">เลขบัตร 4 ตัวท้าย</div>
                    <v-text-field
                      class="my-5 mb-2"
                      maxlength="4"
                      v-model="form.pay_reference"
                      dense
                      hide-details
                      outlined
                    />
                  </template>
                  <div
                    v-if="form.payment_type != 1"
                    class="error--text ml-2 mb-2"
                  >
                    {{ errors.pay_reference }}
                  </div>

                  <div v-if="overdue">
                    <div class="fz-3">ผ่อนชำระ เป็นเวลา</div>
                    <v-select
                      class="mt-5"
                      :items="month"
                      v-model="price.period"
                      @change="handleInstallment(price.auto)"
                      label=""
                      dense
                      hide-details
                      outlined
                    />
                    <v-checkbox
                      class=""
                      label="ผ่อนชำระอัตโนมัติ"
                      v-model="price.auto"
                      @change="handleInstallment"
                    />
                    <div class="primary--text fz-4" v-if="price.auto">
                      ผ่อนเดือนละ
                      {{ (overdue / price.period) | numeral('0,0.00') }} บาท
                    </div>

                    <template v-for="(value, i) in form.pay_installment" v-else>
                      <v-text-field
                        class="mt-5"
                        :label="`เดือนที่ ${i + 1} (บาท)`"
                        :key="i"
                        dense
                        hide-details
                        outlined
                        v-model.number="form.pay_installment[i]"
                        @input="changeInstallment(i)"
                        :disabled="i == 0"
                      />
                    </template>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-spacer></v-spacer>
            </v-col>
            <v-col cols="2">
              <div class="text-center pt-3">* วันที่การชำระเงิน</div>
            </v-col>
            <v-col cols="3">
              <v-card>
                <span class="d-flex float-left">
                  <DatePicker
                    label=""
                    v-model="form.payment_date"
                    @onChange="(val) => (form.payment_date = val)"
                  />
                </span>
              </v-card>
            </v-col>
            <v-col cols="2">
              <div class="text-center pt-3">* เวลาการชำระเงิน</div>
            </v-col>
            <v-col cols="2">
              <v-card>
                <span class="d-flex float-left">
                  <TimePicker
                    label=""
                    v-model="form.payment_time"
                    @onChange="(val) => (form.payment_time = val)"
                  />
                </span>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-btn
                color="grey"
                block
                outlined
                @click="cancel()"
                :disabled="loading"
                >ยกเลิก</v-btn
              >
            </v-col>
            <v-col cols="10">
              <v-btn
                color="error"
                block
                @click="confirm()"
                :loading="loading"
                :disabled="loading"
                >ยืนยันการชำระเงิน</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog persistent scrollable max-width="600" v-model="dialogSuccess">
      <Success
        :item_id="document_id"
        @onClose="dialogSuccess = false"
      ></Success>
    </v-dialog>
  </v-row>
</template>

<script>
//import PrintDocument from '@/components/PrintDocument/printDocument.vue';
import Success from '@/views/pages/document/components/Success.vue';
import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';
import * as moment from 'moment';
import { required, numeric, minValue } from 'vuelidate/lib/validators';

export default {
  components: {
    Success,
    //PrintDocument,
    DatePicker,
    TimePicker,
  },

  validations: {
    price: {
      getMoney: { required, numeric },
    },
  },

  data: () => ({
    loading: true,
    dialogSuccess: false,
    document_id: '',
    headers: [
        {
          text: 'สินค้า',
          align: 'start',
          value: 'name',
        },
        { text: 'ราคา', value: 'price' },
      ],
    form: {
      payment_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
      payment_time: '',
      document_id: '',
      payment_type: 1,
      pay_reference: '',
      pay_installment: [],
    },
    data: {
      customer_name: '',
      car_plate: '',
      car_province: '',
      product: [],
    },
    price: {
      period: 1, //งวด
      auto: true,
      bank: 1,
      getMoney: 0,
    },
    list: {
      car_type_dict: [
        { value: 'CT1', name: '(รย.1) รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน' },
        { value: 'CT2', name: '(รย.2) รถยนต์นั่งส่วนบุคคลเกิน 7 คน' },
        { value: 'CT3', name: '(รย.3) รถยนต์บรรทุกส่วนบุคคล' },
        { value: 'CT4', name: '(รย.12) รถจักรยานยนต์ส่วนบุคคล' },
        { value: 'CT13', name: '(รย.17) รถจักรยานยนต์สาธารณะ' },
        { value: 'CT5', name: '(รย.13) รถแทรกเตอร์ที่ใช้ในการเกษตร' },
        { value: 'CT14', name: '(รย.15) รถใช้งานเกษตรกรรม' },
        {
          value: 'CT6',
          name: '(ขส.10) รถโดยสารประจำทาง (เลขทะเบียนข้างหน้าเป็น 10-19)',
        },
        {
          value: 'CT7',
          name: '(ขส.30) รถโดยสารไม่ประจำทาง (เลขทะเบียนข้างหน้าเป็น 30-39)',
        },
        {
          value: 'CT8',
          name: '(ขส.40) รถโดยสารส่วนบุคคล (เลขทะเบียนข้างหน้าเป็น 40-49)',
        },
        {
          value: 'CT9',
          name: '(ขส.70) รถบรรทุกไม่ประจำทาง (เลขทะเบียนข้างหน้าเป็น 70-79)',
        },
        {
          value: 'CT10',
          name: '(ขส.80) รถบรรทุกส่วนบุคคล (เลขทะเบียนข้างหน้าเป็น 50-59, 80-99)',
        },
        { value: 'CT11', name: '(รย.1EV) รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน' },
        { value: 'CT12', name: 'อื่นๆ' },
      ],
    },
    month: [
      { text: '1 เดือน', value: 1 },
      { text: '2 เดือน', value: 2 },
      { text: '3 เดือน', value: 3 },
      { text: '4 เดือน', value: 4 },
      { text: '5 เดือน', value: 5 },
      { text: '6 เดือน', value: 6 },
      { text: '7 เดือน', value: 7 },
      { text: '8 เดือน', value: 8 },
      { text: '9 เดือน', value: 9 },
      // { text: '10 เดือน', value: 10},
      // { text: '11 เดือน', value: 11},
      // { text: '12 เดือน', value: 12},
    ],
    banks: [],
    errors: {
      pay_reference: '',
    },
  }),

  async created() {
    this.loading = true;
    this.getBanks();
    this.form.document_id = this.$route.params.id;
    this.document_id = this.$route.params.id;
    await this.getData();
    //await this.getDataPrint();
    this.loading = false;
  },
  methods: {
    onChangeTime(val, kayName) {
      this.form[kayName] = `${val}:00`;
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(
          { document_id: this.document_id },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/get_by_id`, body)
        .then(async (res) => {
          this.data = { ...res.result };
          this.form.payment_date = this.form.payment_date;
          this.form.payment_time = moment()
            .set({
              hour: parseInt(moment(this.form.payment_date).format('HH')),
              minute: parseInt(moment(this.form.payment_date).format('mm')),
            })
            .format('HH:mm');
        })
        .catch((err) => {
          console.log('err', err);
        });
      this.loading = false;
    },
    getDateText(date) {
      let yearOffset = 543;
      let d = moment(date);
      return (
        d.format('DD/MM') + '/' + (parseInt(d.format('YYYY')) + yearOffset)
      );
    },
    async getBanks() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$store.state.selected_branch._id },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/branch/get_bank_account`, body)
        .then(async (res) => {
          this.banks = res.result;
          this.banks.unshift('');
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    // async getDataPrint() {
    //   let body = {
    //     token: this.$jwt.sign(
    //       { document_id: this.document_id },
    //       this.$privateKey,
    //       { noTimestamp: true }
    //     ),
    //   };
    //   await this.$axios
    //     .post(`${this.$baseUrl}/document/print_document_data`, body)
    //     .then(async (res) => {
    //       this.getDataPrint = res.result;
    //     })
    //     .catch((err) => {
    //       console.log('err', err);
    //     });
    // },
    cancel() {
      this.$router.push({
        name: 'document-invoice-update',
        params: { id: this.document_id },
      });
    },
    handleInstallment(val) {
      this.form.pay_installment = [];
      let tprice = this.price.getMoney != '' ? this.price.getMoney : 0;
      if (this.overdue) {
        let price = this.overdue / this.price.period;
        this.form.pay_installment.push(parseFloat(tprice.toFixed(2)));
        for (let index = 0; index < this.price.period; index++) {
          this.form.pay_installment.push(parseFloat(price.toFixed(2)));
        }
      }
    },
    changeInstallment(idx) {
      let temp_pay_installment = [];
      let paid_price = 0;
      let tprice = this.price.getMoney != '' ? this.price.getMoney : 0;
      if (this.overdue) {
        temp_pay_installment.push(parseFloat(tprice.toFixed(2)));
        for (let index = 1; index <= idx; index++) {
          temp_pay_installment.push(this.form.pay_installment[index]);
          paid_price += this.form.pay_installment[index];
        }
        let price = (this.overdue - paid_price) / (this.price.period - idx);
        for (let index = idx; index < this.price.period; index++) {
          temp_pay_installment.push(parseFloat(price.toFixed(2)));
        }
      }
      this.form.pay_installment = temp_pay_installment;
    },
    async confirm() {
      this.valid = true;
      this.errors = {};
      let errors = [];
      if (this.price.getMoney == '' || this.price.getMoney == '') {
        this.errors.getMoney = 'กรุณาระบุจำนวนเงิน.';
        errors.push('กรุณาระบุ');
        this.valid = false;
      }
      if (this.form.payment_type != 1 && this.form.pay_reference == '') {
        this.errors.pay_reference = 'กรุณาระบุ';
        this.valid = false;
      }
      if (this.valid) {
        this.loading = true;
        this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
          async (result) => {
            if (result.isConfirmed) {
              this.form.pay_installment =
                this.form.pay_installment.length == 0
                  ? [parseFloat(this.price.getMoney.toFixed(2))]
                  : this.form.pay_installment;
              this.form.payment_date =
                this.form.payment_date.split('T')[0] +
                'T' +
                this.form.payment_time.split(':')[0] +
                ':' +
                this.form.payment_time.split(':')[1];
              let body = {
                token: this.$jwt.sign(this.form, this.$privateKey, {
                  noTimestamp: true,
                }),
              };
              await this.$axios
                .post(`${this.$baseUrl}/document/pay_document`, body)
                .then((res) => {
                  if (res.status == 'package limit') {
                    this.$alertServerError({
                      title: 'ไม่มีจำนวนบิลคงเหลือหรือแพ็คเกจหมดอายุ',
                    });
                  } else {
                    this.document_id = res.result;
                    this.dialogSuccess = true;
                  }
                })
                .catch((err) => {
                  console.log('err', err);
                  this.$alertServerError({ title: err.error_message });
                });
            }
            this.loading = false;
          }
        );
      }
    },
    async addMoney(amount = 0) {
      if (this.price.getMoney == "") {
        this.price.getMoney = 0
      }
        if (amount == 0) {
          this.price.getMoney = this.data.net_price
        } else {
          this.price.getMoney += amount;
        }
    },
  },
  watch: {
    'price.getMoney': function (newVal, oldVal) {
      if (isNaN(newVal) || newVal === null || newVal === undefined) {
        this.price.getMoney = 0;
      }
      this.handleInstallment(this.price.auto);
    },
    'form.payment_type': function (newVal, oldVal) {
      if (newVal == 1) this.form.pay_reference = '';
      else if (newVal == 2) this.form.pay_reference = this.banks[0];
      else if (newVal == 3) this.form.pay_reference = '';
    },
    overdue: function (newVal, oldVal) {
      this.handleInstallment(this.price.auto);
    },
  },
  computed: {
    change() {
      let price = this.price.getMoney - this.data.net_price;
      return price > 0 ? price : 0;
    },
    overdue() {
      let price = this.data.net_price - this.price.getMoney;
      return price > 0 ? price : 0;
    },
    carTypeName() {
      const carType = this.list.car_type_dict.find(
        (item) => item.value === this.data.car_type
      );
      return carType ? carType.name : '';
    },
  },
};
</script>
